























import { Route } from 'vue-router'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import { EntityResource } from '@/models/implementations/entities/EntityResource'
import { ImplementationResource } from '@/models/implementations/ImplementationResource'
import { ImplementationProposalResource } from '@/models/implementations/proposal/ImplementationProposalResource'
import { ImplementationEntityProposalResource } from '@/models/implementations/proposal/ImplementationEntityProposalResource'

import SmallLoader from '@/components/loaders/SmallLoader.vue'
import ProcessIndexList from './../../implementations/partials/ProcessIndexList.vue'
import ProcessList from './../../implementations/partials/ProcessList.vue'

type watchedRoute = Route

@Component({
  components: {
    ProcessList,
    SmallLoader,
    ProcessIndexList
  }
})
export default class ImplementationDetailImplementations extends Vue {

  @Prop()
  private implementation!: ImplementationResource

  @Prop()
  private entity!: EntityResource

  @Watch('$route')
  private onRouteChange(val: watchedRoute): void {
    if (val.params.proposal_id) {
      this.scrollTo(`proposal-${val.params.proposal_id}`)
    }
  }

  private mounted(): void {
    if (this.$route.params.proposal_id) {
      this.scrollTo(`proposal-${this.$route.params.proposal_id}`)
    }
  }

  private get selectedProposal(): ImplementationEntityProposalResource | void {
    const proposal_id = this.$route.params.proposal_id
    if (!proposal_id) return

    const proposals = this.entity.processes.map((process) => {
      return process.proposals
    }).flat()
    return proposals.find((proposal) => proposal.id === +proposal_id)
  }

  private scrollTo(id: string) {
    document.getElementById(id)?.scrollIntoView({
      block: 'center',
      inline: 'nearest',
      behavior: 'smooth'
    })
  }

}
